import React from "react"
import styled from 'styled-components'

const StyledBlobPhone = styled.svg`
// Extra small devices (portrait phones, less than 576px)
// No media query for xs since this is the default in Bootstrap

z-index: 1 !important;
position: absolute;
top: 100;
left: 0;
width: 56vmax;

// Small devices (landscape phones, 576px and up, col-sm)
@media (min-width: 576px) {
    display: none;
}
`
const StyledBlobLandscapePhone = styled.svg`
// Extra small devices (portrait phones, less than 576px)
// No media query for xs since this is the default in Bootstrap

display: none;

// Small devices (landscape phones, 576px and up, col-sm)
@media (min-width: 576px) {

    display: flex;
    z-index: 1 !important;
    position: absolute;
    top: -10%;
    left: 8%;
    width: 80vmax;
}
// Medium devices (tablets, 768px and up, col-md)
@media (min-width: 768px) {
    
    display: none;
}
`
const StyledBlobTablet = styled.svg`
// Extra small devices (portrait phones, less than 576px)
// No media query for xs since this is the default in Bootstrap

display: none;

/* // Small devices (landscape phones, 576px and up, col-sm)
@media (min-width: 576px) {
} */

// Medium devices (tablets, 768px and up, col-md)
@media (min-width: 768px) {
    
    display: flex;
    z-index: 1 !important;
    position: absolute;
    top: 100;
    left: -10%;
    width: 80vmax;

}
// Large devices (desktops, 992px and up, col-lg)
@media (min-width: 992px) {
    
    display: none;

}
`
const StyledBlobDesktop = styled.svg`
// Extra small devices (portrait phones, less than 576px)
// No media query for xs since this is the default in Bootstrap

display: none;

// Large devices (desktops, 992px and up, col-lg)
@media (min-width: 992px) {

    display: flex;
    z-index: 1 !important;
    position: absolute;
    top: -16%;
    left: 0;
    width: 90vmax;

}
// Extra large devices (large desktops, 1200px and up, col-xl)
@media (min-width: 1200px) {

}
`
/*     animation: move 10s ease-in-out infinite;
    transform-origin: 50% 50%; */

/*     @keyframes move {
  0%   { transform: scale(1)   translate(10px, -30px); }
  38%  { transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg); }
  40%  { transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg); }
  78%  { transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg); }
  80%  { transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg); }
  100% { transform: scale(1)   translate(10px, -30px); }
} */

function Blob() {
    return (
        <>
        <StyledBlobPhone xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <path
                fill="rgba(128,201,155,0.3)"
                d="M159.2 33.8c16.4 9.4 27.7 29.1 27 47.7-.7 18.5-13.4 35.8-26 49.1-12.6 13.2-25.1 22.4-38.1 24.9-13.1 2.5-26.6-1.6-40.7-6-14.1-4.4-28.6-9.1-40.8-20.1-12.1-11-21.8-28.2-17.4-41.1 4.4-13 23-21.6 38.2-31.2 15.2-9.7 26.9-20.3 43.4-26.1 16.6-5.8 38-6.7 54.4 2.8z"
            ></path>
        </StyledBlobPhone>
        <StyledBlobLandscapePhone xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <path
                fill="rgba(128,201,155,0.3)"
                d="M159.2 33.8c16.4 9.4 27.7 29.1 27 47.7-.7 18.5-13.4 35.8-26 49.1-12.6 13.2-25.1 22.4-38.1 24.9-13.1 2.5-26.6-1.6-40.7-6-14.1-4.4-28.6-9.1-40.8-20.1-12.1-11-21.8-28.2-17.4-41.1 4.4-13 23-21.6 38.2-31.2 15.2-9.7 26.9-20.3 43.4-26.1 16.6-5.8 38-6.7 54.4 2.8z"
            ></path>
        </StyledBlobLandscapePhone>
        <StyledBlobTablet xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <path
                fill="rgba(128,201,155,0.3)"
                d="M159.2 33.8c16.4 9.4 27.7 29.1 27 47.7-.7 18.5-13.4 35.8-26 49.1-12.6 13.2-25.1 22.4-38.1 24.9-13.1 2.5-26.6-1.6-40.7-6-14.1-4.4-28.6-9.1-40.8-20.1-12.1-11-21.8-28.2-17.4-41.1 4.4-13 23-21.6 38.2-31.2 15.2-9.7 26.9-20.3 43.4-26.1 16.6-5.8 38-6.7 54.4 2.8z"
            ></path>
        </StyledBlobTablet>
        <StyledBlobDesktop xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <path
                fill="rgba(128,201,155,0.3)"
                d="M159.2 33.8c16.4 9.4 27.7 29.1 27 47.7-.7 18.5-13.4 35.8-26 49.1-12.6 13.2-25.1 22.4-38.1 24.9-13.1 2.5-26.6-1.6-40.7-6-14.1-4.4-28.6-9.1-40.8-20.1-12.1-11-21.8-28.2-17.4-41.1 4.4-13 23-21.6 38.2-31.2 15.2-9.7 26.9-20.3 43.4-26.1 16.6-5.8 38-6.7 54.4 2.8z"
            ></path>
        </StyledBlobDesktop>
        </>
    )
}

export default Blob