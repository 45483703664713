import * as React from 'react'
import { Row, Col } from 'react-bootstrap'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'
import linkStyles from './styled/linkStyles'
import styled from 'styled-components'

export const OneColSection = ({ content, marginTop, hide}) => {

    const text = (<StyledRow marginTop={marginTop}>
            <Col md={1} lg={2}></Col>
            <Col>
                <RichText render={content} linkResolver={linkResolver}/>
            </Col>
            <Col md={1} lg={2}></Col>
        </StyledRow>)

    return hide ? null : text
    
}

    const StyledRow = styled(Row)`
      // Extra small devices (portrait phones, less than 576px)
      // No media query for xs since this is the default in Bootstrap

              padding-top: ${(props) => props.marginTop}px;

        a {
          ${linkStyles}
        }

      // Small devices (landscape phones, 576px and up, col-sm)
      @media (min-width: 576px) {
      }
      // Medium devices (tablets, 768px and up, col-md)
      @media (min-width: 768px) {
      }
      // Large devices (desktops, 992px and up, col-lg)
      @media (min-width: 992px) {

        padding-top: ${(props) => (props.marginTop * 2)}px;

      }

      // Extra large devices (large desktops, 1200px and up, col-xl)
      @media (min-width: 1200px) {
      }

      /*     h4 {
            font-size: 15rem !important;
        } */
    `