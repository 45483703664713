import styled from 'styled-components'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const StyledTopWave = styled(Row)`
// Extra small devices (portrait phones, less than 576px)
// No media query for xs since this is the default in Bootstrap
display: ${props => props.pathname !== "/" ? "block" : props.mobileScroll ? "block" : "none"};
height: 320px;
background-repeat: no-repeat;
background-size: cover;
width: 100%;
margin-right: 0 !important;
margin-left: 0 !important;
margin-bottom: -15rem;
margin-top: -50px !important;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%235CA577' fill-opacity='1' d='M0,96L48,101.3C96,107,192,117,288,106.7C384,96,480,64,576,80C672,96,768,160,864,176C960,192,1056,160,1152,128C1248,96,1344,64,1392,48L1440,32L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z'%3E%3C/path%3E%3C/svg%3E");

// Small devices (landscape phones, 576px and up, col-sm)
@media (min-width: 576px) {
  
}
// Medium devices (tablets, 768px and up, col-md)
@media (min-width: 768px) {
  margin-bottom: -20rem;
  height: 420px;
  
}
// Large devices (desktops, 992px and up, col-lg)
@media (min-width: 992px) {
  
  display: ${props => props.pathname !== "/" ? "block" : props.scroll ? "block" : "none"};
  margin-top: -64px !important;
  margin-bottom: -15rem;
  height: 340px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%235CA577' fill-opacity='1' d='M0,256L48,218.7C96,181,192,107,288,96C384,85,480,139,576,149.3C672,160,768,128,864,112C960,96,1056,96,1152,90.7C1248,85,1344,75,1392,69.3L1440,64L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z'%3E%3C/path%3E%3C/svg%3E");
}
// Extra large devices (large desktops, 1200px and up, col-xl)
@media (min-width: 1200px) {
  
}`

const StyledBottomWave = styled(Col)`
// Extra small devices (portrait phones, less than 576px)
// No media query for xs since this is the default in Bootstrap

background-repeat: no-repeat;
background-size: cover;
width: 100%;
height: 100px;
z-index: -1;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%232C2A4A' fill-opacity='1' d='M0,96L48,101.3C96,107,192,117,288,106.7C384,96,480,64,576,80C672,96,768,160,864,176C960,192,1056,160,1152,128C1248,96,1344,64,1392,48L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'%3E%3C/path%3E%3C/svg%3E");

// Small devices (landscape phones, 576px and up, col-sm)
@media (min-width: 576px) {
  
}
// Medium devices (tablets, 768px and up, col-md)
@media (min-width: 768px) {
  height: 110px;
  
}
// Large devices (desktops, 992px and up, col-lg)
@media (min-width: 992px) {
    height: 242px;
}
// Extra large devices (large desktops, 1200px and up, col-xl)
@media (min-width: 1200px) {
  
}`

export { StyledTopWave, StyledBottomWave }